import React from "react";
import Button from "@material-ui/core/Button";

import topLogo from "./logo-image-petrossian.png";
import bottomLogo from "./logo-texte-petrossian.png";
import "./App.css";

import { generatePDFFull } from "./pdf.js";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}));

function App() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    data: [],
    valid: false,
    exits: false,
  });
  const [values, setValues] = React.useState({
    email: "Cat in the Hat",
    Password: "",
    age: "",
    multiline: "Controlled",
    currency: "EUR",
  });
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const [selectedDate, setSelectedDate] = React.useState({
    value: new Date(),
    exists: true,
  });

  async function handleDateChange(date) {
    let month = parseInt(date.getMonth()) + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = parseInt(date.getDate());
    if (day < 10) {
      day = "0" + day;
    }
    const newSelectedValue = { ...selectedDate, value: date };
    await fetch(
      "/image?date=" + date.getFullYear() + "-" + month + "-" + day
    ).then((res) => (newSelectedValue.exists = res.status !== 404));
    setSelectedDate(newSelectedValue);
    extractData(newSelectedValue.value.getFullYear() + "-" + month + "-" + day);
  }
  var month = parseInt(selectedDate.value.getMonth()) + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = parseInt(selectedDate.value.getDate());
  if (day < 10) {
    day = "0" + day;
  }

  const extractData = (Date) => {
    axios
      .get("/database?date=" + Date)
      .then((data) => {
        setState({ data: data.data.data, valid: true, exists: false });
      })
      .catch((error) => console.log(error));
  };
  console.log("state", state);
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img id="top-layer" src={topLogo} className="App-logo" alt="logo" />
        </div>
        <div>
          <img
            id="bottom-layer"
            src={bottomLogo}
            className="App-logo"
            alt="logo"
          />
        </div>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <form className={classes.container} noValidate autoComplete="on">
          <TextField
            id="standard-name"
            label="Email"
            className={classes.textField}
            placeholder="Email"
            onChange={handleChange("email")}
            margin="normal"
          />
          <TextField
            id="password-name"
            label="Password"
            className={classes.textField}
            placeholder="Password"
            onChange={handleChange("Password")}
            margin="normal"
          />
        </form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date to extract"
              format="dd/MM/yyyy"
              locale="fr"
              okLabel="OK"
              cancelLabel="Annuler"
              value={selectedDate.value}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>

        {/* <Button variant="contained" color="primary" onClick={(e) => generatePDFReport(state.data,date+ "-" + month + "-" + selectedDate.getFullYear(),state.valid,values.email,values.Password)}>Save Report</Button>
        <Button variant="contained" color="primary" onClick={(e) => generatePDFForm(selectedDate.getFullYear()+ "-" + month + "-" +date, state.valid,values.email,values.Password )}>Save Form</Button> */}
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            !selectedDate.exists
              ? alert("Date introuvable")
              : generatePDFFull(
                state.data,
                selectedDate.value.getFullYear() + "-" + month + "-" + day,
                state.valid,
                values.email,
                values.Password
              );
          }}
        >
          Save Form and Report
        </Button>
      </header>
    </div>
  );
}

export default App;
