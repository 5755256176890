import * as jsPDF from "jspdf";
import * as md5 from "blueimp-md5";

// var regex = /(],)/gi;
// var docu =document.getElementById("text");
// console.log (JSON.stringify(doc.getFontList()))
// docu.innerHTML = JSON.stringify(doc.getFontList()).replace(/(],)/gi,"]<br>")

function getDataUri(url, callback) {
    var image = new Image();

    image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext("2d").drawImage(this, 0, 0);

        // Get raw image data
        //callback(canvas.toDataURL(url).replace(/^data:image\/(png|jpg);base64,/, ''));

        // ... or get as Data URI
        callback(canvas.toDataURL(url));
    };
    image.crossOrigin = "anonymous";
    image.src = url;
    return image;
}

async function checkLogin(login, password) {
    function setValue(val) {
        value = val;
    }
    var value = false;
    return await fetch("./login?email=" + login + "&password=" + password)
        .then((res) => {
            console.log(res.status === 200);
            if (res.status === 200 || res.status === 304) {
                setValue(true);
                console.log(value);
                return true;
            } else {
                return false;
            }
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

function drawCheckbox(doc, x, y) {
    //line(x depart, y depart, x fin, y fin)
    doc.setLineDash();
    doc.line(x, y, x, y - 3);
    doc.line(x, y - 3, x + 3, y - 3);
    doc.line(x + 3, y - 3, x + 3, y);
    doc.line(x + 3, y, x, y);
}
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max)) + 1;
}

export const generatePDFReport = (data, date, valid) => {
    if (!valid) {
        alert("Date non Validé");
        return;
    }
    var doc = jsPDF();
    var startTable = 50;
    var underline = startTable;
    var m = 0;
    var n = 0;
    var pageAdded = false;
    var NbLigne = 15;
    doc.setFontSize(30);
    doc.text("Report " + date, 15, 25, 0, 30);
    doc.setFontSize(10);
    doc.setDrawColor(150, 150, 150);
    doc.setLineWidth(0.1);
    doc.line(10, 30, 200, 30);
    doc.setFillColor(200, 200, 200);
    doc.rect(20, 40, 70, 10, "F");
    doc.text("Lots", startTable / 2, startTable - 3);
    doc.text("Quantities", 70, startTable - 3);
    doc.setLineWidth(1);
    doc.line(20, underline, 90, underline);
    doc.setFontSize(10);
    doc.setLineWidth(0.1);
    doc.line(10, 30, 200, 30);
    doc.setFillColor(200, 200, 200);
    doc.rect(110, 40, 70, 10, "F");
    doc.text("Lots", startTable * 2.2 + 6, startTable - 3);
    doc.text("Quantities", 160, startTable - 3);
    doc.setLineWidth(1);
    doc.line(110, underline, 180, underline);

    var lastLot1 = "a";
    var lastLot8 = "";
    var lot1 = [];
    var lot8 = [];
    var lotCount = [0, 0];
    data.forEach((element) => {
        if (element.lots.startsWith("1") || element.lots.startsWith("2")) {
            lot1.push(element);
            if (lastLot1 !== element.lots) {
                lotCount[0]++;
                lastLot1 = element.lots;
            }
        } else if (element.lots.startsWith("8") || element.lots.startsWith("9")) {
            lot8.push(element);
            if (lastLot8 !== element.lots) {
                lotCount[1]++;
                lastLot8 = element.lots;
            }
        }
    });
    //    lastLot1 = "a";
    //    lastLot8 = "";
    //Comptage nombre de page
    const upperlimit = Math.max(lot8.length, lot1.length);
    const maxPageCount = Math.ceil(
        (upperlimit + Math.max(...lotCount)) / NbLigne
    );
    var pageNumber = 1;
    doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
    console.log("lot1", lot1);
    console.log("Beginning of table draw");
    doc.setLineWidth(0.1);
    for (var i = 0; i <= upperlimit; i++) {
        //Lot commencant par 1
        if (i < lot1.length) {
            if (i % NbLigne === 0 && i !== 0) {
                //ajout d'une nouvelle page avec ses headers
                doc.addPage("a4");
                pageNumber++;
                doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
                pageAdded = true;
                doc.setFontSize(30);
                doc.text("Report " + date, 15, 25, 0, 30);
                doc.line(10, 30, 200, 30);
                doc.setFontSize(10);
                //reset hauteur de ligne
                m = 0;
                //Premier code et lot de la nouvelle page
                doc.text(lot1[i].lots, underline / 2, startTable - 3 + m * 10);
                doc.line(20, underline + m * 10, 90, underline + m * 10);
                doc.text(lot1[i].code, 50, startTable - 3 + (m + 1) * 10);
                doc.text(lot1[i].total.toString(), 80, startTable - 3 + (m + 1) * 10);
                doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);

                //m--;
            } else {
                if (lastLot1 !== lot1[i].lots) {
                    //Changement de numero de lot
                    doc.text(lot1[i].lots, underline / 2, startTable - 3 + (m + 1) * 10);
                    doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);

                    lastLot1 = lot1[i].lots;
                    m++;
                }
                //ecriture valeur
                doc.text(lot1[i].code, 50, startTable - 3 + (m + 1) * 10);
                doc.text(lot1[i].total.toString(), 80, startTable - 3 + (m + 1) * 10);
                doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);
            }
            m++;
        }
        //Lot Commencant par 8
        if (i < lot8.length) {
            doc.line(20, underline + (n + 1) * 10, 70, underline + (n + 1) * 10);
            doc.setLineWidth(0.1);
            if (i % NbLigne === 0 && i !== 0) {
                if (!pageAdded) {
                    doc.addPage("a4");
                    pageNumber++;
                    doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
                    doc.line(10, 30, 200, 30);
                }
                n = 0;

                doc.text(lastLot8, underline * 2.35, startTable - 3 + n * 10);
                //n--
                doc.line(110, underline + n * 10, 180, underline + n * 10);
                doc.text(lot8[i].code, 140, startTable - 3 + (n + 1) * 10);
                doc.text(lot8[i].total.toString(), 170, startTable - 3 + (n + 1) * 10);
                doc.line(110, underline + (n + 1) * 10, 180, underline + (n + 1) * 10);
            } else {
                if (lastLot8 !== lot8[i].lots) {
                    doc.text(
                        lot8[i].lots,
                        underline * 2.35,
                        startTable - 3 + (n + 1) * 10
                    );
                    doc.line(
                        110,
                        underline + (n + 1) * 10,
                        180,
                        underline + (n + 1) * 10
                    );
                    lastLot8 = lot8[i].lots;
                    n++;
                }
                doc.text(lot8[i].code, 140, startTable - 3 + (n + 1) * 10);
                doc.text(lot8[i].total.toString(), 170, startTable - 3 + (n + 1) * 10);
                doc.line(110, underline + (n + 1) * 10, 180, underline + (n + 1) * 10);
            }
            n++;
            pageAdded = false;
        }
    }
    doc.save("Report " + date);
    return doc;
};
export const generatePDFForm = (date, valid) => {
    if (!valid) {
        alert("Date non Validé");
        return;
    }
    var doc = new jsPDF();

    //Bloc 1 lignes
    doc.line(8, 6.5, 203, 6.5); // horizontal line
    doc.line(8, 6.5, 8, 20.5); // vertical line
    doc.line(203, 6.5, 203, 20.5);
    doc.line(8, 20.5, 203, 20.5);
    doc.line(38, 6.5, 38, 20.5);
    doc.line(148, 6.5, 148, 20.5);

    // Bloc1 texte
    doc.text("FABRICATION DE PRODUIT FRAIS", 93, 15, "center");
    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("Groupe", 23, 12.5, "center");
    doc.text("PETROSSIAN", 23, 18, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Date : 01/09/2019", 201, 19, "right");
    doc.text("IR : 9", 201, 14.5, "right");
    doc.text("Réf: E 03 C", 201, 10, "right");
    doc.setFontSize(12);
    doc.text("Date : ", 38, 26);

    var data = getDataUri("/image?date=" + date, function(dataUri) {
        return dataUri;
    });
    console.log(data);
    doc.addImage(data, 65, 11, 40, 20);

    //Bloc 2 lignes
    //line(x depart, y depart, x fin, y fin)
    doc.line(8, 28, 203, 28);
    doc.line(8, 36, 203, 36);
    doc.line(8, 28, 8, 61.5);
    doc.line(203, 28, 203, 61.5);
    doc.line(8, 44.5, 203, 44.5);
    doc.line(8, 61.5, 203, 61.5);
    doc.line(48, 36, 48, 61.5);
    doc.line(76.5, 36, 76.5, 61.5);
    doc.line(120.5, 36, 120.5, 61.5);
    doc.line(8, 53, 120.5, 53);

    //Bloc 2 texte

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("FABRICATION MAVIAR", 105.5, 33, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("N°lot", 62, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Oeuf de cabillaud", 28, 50, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Crème", 28, 59, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Quantité (kg ou g)", 99, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Produit fini", 162, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("CODE:", 123, 50);
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("LOT:", 123, 55);
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("QUANTITE:", 123, 60);
    // bloc 2 dotted line

    doc.setLineDash([0.6]);
    doc.line(135, 50, 160, 50);
    doc.setLineDash([0.6]);
    doc.line(132, 55, 160, 55);
    doc.setLineDash([0.6]);
    doc.line(145, 60, 160, 60);

    //Bloc 3 lignes
    doc.setLineDash(); //reset line param
    doc.line(8, 67.5, 203, 67.5);
    doc.line(8, 75, 203, 75);
    doc.line(8, 93, 203, 93);
    doc.line(8, 100, 203, 100);
    doc.line(8, 118, 203, 118);
    doc.line(8, 136, 203, 136);
    doc.line(8, 158, 203, 158);
    doc.line(8, 165, 203, 165);
    doc.line(8, 183, 203, 183);
    doc.line(8, 190, 203, 190);
    doc.line(8, 198, 203, 198);
    doc.line(8, 205, 203, 205);
    doc.line(8, 227, 203, 227);

    doc.line(8, 67.5, 8, 227);
    doc.line(203, 67.5, 203, 227);

    doc.line(103, 165, 103, 183);
    doc.line(83, 205, 83, 227);
    doc.line(156, 205, 156, 227);

    //Bloc 3 texte

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONTROLE FRAICHEUR DES OEUFS DE POISSON ", 105.5, 73, "center");

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("CONFORME", 16, 83);
    drawCheckbox(doc, 11, 83);

    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 9, 78, 7, 7);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("ACCEPTABLE", 55, 83);
    drawCheckbox(doc, 51, 83);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("NON CONFORME", 94, 83);
    drawCheckbox(doc, 90, 83);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("Préciser la non-conformité :", 10, 91);
    doc.setLineDash([0.6]);
    doc.line(63, 91, 98, 91);
    doc.setLineDash();

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONTROLE DE FERMETURE VERRINES/BOITES ", 105.5, 98, "center");

    doc.setFontSize(10);
    doc.text("Sertissage machine/main : ", 10, 106);
    doc.setFontStyle("normal");
    doc.text(
        " Tire up (contrôle visuel sur toute les boites, le couvercle recouvre la boite): ",
        62,
        106
    );
    doc.text("Conforme", 10, 113);
    drawCheckbox(doc, 29, 113);
    doc.text("Non conforme", 61, 113);
    drawCheckbox(doc, 90, 113);

    doc.setFontStyle("bold");
    doc.text("Boite sous vide : ", 10, 124);
    doc.setFontStyle("normal");
    doc.text("(le couvercle doit tenir sur toute les boites): ", 43, 124);
    doc.text("Conforme", 10, 131);
    drawCheckbox(doc, 29, 131);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 27, 126, 7, 7);
    doc.text("Non conforme", 61, 131);
    drawCheckbox(doc, 90, 131);

    doc.setFontStyle("bold");
    doc.text("Eggxiting : ", 10, 142);
    doc.setFontStyle("normal");
    doc.text(
        " (Tirer sur la languette qui doit resister ,au démarrage): ",
        32,
        142
    );
    doc.text("Matin : Conforme", 10, 149);
    drawCheckbox(doc, 41, 149);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 39, 144, 7, 7);
    doc.text("Non conforme", 61, 149);
    drawCheckbox(doc, 97, 149);
    doc.text("Après midi : Conforme", 10, 155);
    drawCheckbox(doc, 50, 155);
    doc.text("Non conforme", 71, 155);
    drawCheckbox(doc, 108, 155);

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("BRIS DE VERRINE : PREVENIR SERVICE QUALITE", 105.5, 163, "center");
    doc.setFontSize(10);
    doc.text("Lieu : ", 9, 170);
    doc.text("Action : ", 106, 170);
    doc.setFontStyle("normal");
    doc.text("Salle préparation caviar ", 25.5, 170);
    drawCheckbox(doc, 74, 170);
    doc.text("Zone déballage carton ", 25.5, 178);
    drawCheckbox(doc, 74, 178);
    doc.text("Tri et lavage ", 124.5, 170);
    drawCheckbox(doc, 148, 170);
    doc.text("Destruction produit ", 158.5, 178);
    drawCheckbox(doc, 148, 178);
    doc.text("Destruction carton ", 113.5, 178);
    drawCheckbox(doc, 193, 178);

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text(
        "CONTROLE VERRE,METAL,PLASTIQUE DUR risquant de tomber dans le produit ",
        105.5,
        188,
        "center"
    );

    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Pas de casse ", 38, 195);
    drawCheckbox(doc, 33.5, 195);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 31.5, 190, 7, 7);
    doc.text("Casse : ", 117.5, 195);
    drawCheckbox(doc, 112, 195);
    doc.setLineDash([0.6]);
    doc.line(131.5, 195, 167, 195);
    doc.setLineDash(); //reset line param

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONFORMITE PRODUIT FINIS:", 105.5, 203, "center");
    doc.setFontSize(10);
    doc.setFontStyle("bold");
    doc.text("CONFORME", 13, 212);
    drawCheckbox(doc, 9, 212);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 7, 207, 7, 7);
    doc.text("NON CONFORME", 13, 217);
    drawCheckbox(doc, 9, 217);
    doc.text("OBSERVATIONS:", 84, 212);
    doc.text("Visa du chef d'équipe:", 157.5, 212);
    data = getDataUri("/date?date=sign", function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 154, 207, 7, 7);
    //Bloc 4 lignes
    doc.line(8, 242, 203, 242);
    doc.line(8, 256, 203, 256);
    doc.line(8, 269, 203, 269);
    doc.line(8, 242, 8, 269);
    doc.line(203, 242, 203, 269);

    //Bloc 4 texte
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text(
        "Contrôle nettoyage : (petit matériel, surface salles et plan de travail, plaque...) : ",
        10,
        236
    );
    doc.text("Propre", 147, 236);
    drawCheckbox(doc, 142, 236);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 140, 231, 7, 7);
    doc.text("Sale", 167, 236);
    drawCheckbox(doc, 162, 236);
    doc.text("Actions correctives", 18.5, 246);
    drawCheckbox(doc, 13.5, 246);
    doc.text("Date et validation:", 8.5, 260);
    //sauvegarde doc
    doc.save(`form-${date}.pdf`);
    return doc;
};

export const generatePDFFull = async (data, date, valid, email, password) => {
    console.log(checkLogin(email, password));
    if (!(await checkLogin(email, password))) {
        alert("invalid email or password");
        return;
    }
    if (!valid) {
        alert("Date non Validé");
        return;
    }
    var doc = jsPDF();
    var startTable = 50;
    var underline = startTable;
    var m = 0;
    var n = 0;
    var pageAdded = false;
    var NbLigne = 13;
    doc.setFontSize(30);
    doc.text("Report " + date, 15, 25, 0, 30);
    doc.setFontSize(10);
    doc.setDrawColor(150, 150, 150);
    doc.setLineWidth(0.1);
    doc.line(10, 30, 200, 30);
    doc.setFillColor(200, 200, 200);
    doc.rect(20, 40, 70, 10, "F");
    doc.text("Lots", startTable / 2, startTable - 3);
    doc.text("Quantities", 70, startTable - 3);
    doc.setLineWidth(1);
    doc.line(20, underline, 90, underline);
    doc.setFontSize(10);
    doc.setLineWidth(0.1);
    doc.line(10, 30, 200, 30);
    doc.setFillColor(200, 200, 200);
    doc.rect(110, 40, 70, 10, "F");
    doc.text("Lots", startTable * 2.2 + 6, startTable - 3);
    doc.text("Quantities", 160, startTable - 3);
    doc.setLineWidth(1);
    doc.line(110, underline, 180, underline);

    var lastLot1 = "a";
    var lastLot8 = "";
    var lot1 = [];
    var lot8 = [];
    var lotCount = [0, 0];
    data.forEach((element) => {
        if (element.lots.startsWith("1") || element.lots.startsWith("2")) {
            lot1.push(element);
            if (lastLot1 !== element.lots) {
                lotCount[0]++;
                lastLot1 = element.lots;
            }
        } else if (element.lots.startsWith("8") || element.lots.startsWith("9")) {
            lot8.push(element);
            if (lastLot8 !== element.lots) {
                lotCount[1]++;
                lastLot8 = element.lots;
            }
        }
    });
    lastLot1 = "a";
    lastLot8 = "";
    //Comptage nombre de page
    const upperlimit = Math.max(lot8.length, lot1.length);
    const maxPageCount = Math.ceil(
        (upperlimit + Math.max(...lotCount)) / NbLigne
    );
    var pageNumber = 1;
    doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
    console.log("lot1", lot1);
    console.log("Beginning of table draw");
    doc.setLineWidth(0.1);
    for (var i = 0; i <= upperlimit; i++) {
        //Lot commencant par 1
        if (i < lot1.length) {
            if (i % NbLigne === 0 && i !== 0) {
                //ajout d'une nouvelle page avec ses headers
                doc.addPage("a4");
                pageNumber++;
                doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
                pageAdded = true;
                doc.setFontSize(30);
                doc.text("Report " + date, 15, 25, 0, 30);
                doc.line(10, 30, 200, 30);
                doc.setFontSize(10);
                //reset hauteur de ligne
                m = 0;
                //Premier code et lot de la nouvelle page
                doc.text(lot1[i].lots, underline / 2, startTable - 3 + m * 10);
                doc.line(20, underline + m * 10, 90, underline + m * 10);
                doc.text(lot1[i].code, 50, startTable - 3 + (m + 1) * 10);
                doc.text(lot1[i].total.toString(), 80, startTable - 3 + (m + 1) * 10);
                doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);

                // m--
            } else {
                if (lastLot1 !== lot1[i].lots) {
                    //Changement de numero de lot
                    doc.text(lot1[i].lots, underline / 2, startTable - 3 + (m + 1) * 10);
                    doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);

                    lastLot1 = lot1[i].lots;
                    m++;
                }
                //ecriture valeur
                doc.text(lot1[i].code, 50, startTable - 3 + (m + 1) * 10);
                doc.text(lot1[i].total.toString(), 80, startTable - 3 + (m + 1) * 10);
                doc.line(20, underline + (m + 1) * 10, 90, underline + (m + 1) * 10);
            }
            m++;
        }
        //Lot Commencant par 8
        if (i < lot8.length) {
            doc.line(20, underline + (n + 1) * 10, 70, underline + (n + 1) * 10);
            doc.setLineWidth(0.1);
            if (i % NbLigne === 0 && i !== 0) {
                if (!pageAdded) {
                    doc.addPage("a4");
                    pageNumber++;
                    doc.text(pageNumber + "/" + maxPageCount, 190, 25, 0, 30);
                    doc.line(10, 30, 200, 30);
                }
                n = 0;

                doc.text(lastLot8, underline * 2.35, startTable - 3 + n * 10);
                //n--
                doc.line(110, underline + n * 10, 180, underline + n * 10);
                doc.text(lot8[i].code, 140, startTable - 3 + (n + 1) * 10);
                doc.text(lot8[i].total.toString(), 170, startTable - 3 + (n + 1) * 10);
                doc.line(110, underline + (n + 1) * 10, 180, underline + (n + 1) * 10);
            } else {
                if (lastLot8 !== lot8[i].lots) {
                    doc.text(
                        lot8[i].lots,
                        underline * 2.35,
                        startTable - 3 + (n + 1) * 10
                    );
                    doc.line(
                        110,
                        underline + (n + 1) * 10,
                        180,
                        underline + (n + 1) * 10
                    );
                    lastLot8 = lot8[i].lots;
                    n++;
                }
                doc.text(lot8[i].code, 140, startTable - 3 + (n + 1) * 10);
                doc.text(lot8[i].total.toString(), 170, startTable - 3 + (n + 1) * 10);
                doc.line(110, underline + (n + 1) * 10, 180, underline + (n + 1) * 10);
            }
            n++;
            pageAdded = false;
        }
    }
    doc.addPage("a4");
    //Bloc 1 lignes
    doc.line(8, 6.5, 203, 6.5); // horizontal line
    doc.line(8, 6.5, 8, 20.5); // vertical line
    doc.line(203, 6.5, 203, 20.5);
    doc.line(8, 20.5, 203, 20.5);
    doc.line(38, 6.5, 38, 20.5);
    doc.line(148, 6.5, 148, 20.5);

    // Bloc1 texte
    doc.text("FABRICATION DE PRODUIT FRAIS", 93, 15, "center");
    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("Groupe", 23, 12.5, "center");
    doc.text("PETROSSIAN", 23, 18, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Date : 01/09/2019", 201, 19, "right");
    doc.text("IR : 9", 201, 14.5, "right");
    doc.text("Réf: E 03 C", 201, 10, "right");
    doc.setFontSize(12);
    doc.text("Date : ", 38, 26);

    data = getDataUri("/image?date=" + date, function(dataUri) {
        return dataUri;
    });
    console.log(data);
    doc.addImage(data, 65, 11, 40, 20);

    //Bloc 2 lignes
    //line(x depart, y depart, x fin, y fin)
    doc.line(8, 28, 203, 28);
    doc.line(8, 36, 203, 36);
    doc.line(8, 28, 8, 61.5);
    doc.line(203, 28, 203, 61.5);
    doc.line(8, 44.5, 203, 44.5);
    doc.line(8, 61.5, 203, 61.5);
    doc.line(48, 36, 48, 61.5);
    doc.line(76.5, 36, 76.5, 61.5);
    doc.line(120.5, 36, 120.5, 61.5);
    doc.line(8, 53, 120.5, 53);

    //Bloc 2 texte

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("FABRICATION MAVIAR", 105.5, 33, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("N°lot", 62, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Oeuf de cabillaud", 28, 50, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Crème", 28, 59, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Quantité (kg ou g)", 99, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Produit fini", 162, 42, "center");
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("CODE:", 123, 50);
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("LOT:", 123, 55);
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("QUANTITE:", 123, 60);
    // bloc 2 dotted line

    doc.setLineDash([0.6]);
    doc.line(135, 50, 160, 50);
    doc.setLineDash([0.6]);
    doc.line(132, 55, 160, 55);
    doc.setLineDash([0.6]);
    doc.line(145, 60, 160, 60);

    //Bloc 3 lignes
    doc.setLineDash(); //reset line param
    doc.line(8, 67.5, 203, 67.5);
    doc.line(8, 75, 203, 75);
    doc.line(8, 93, 203, 93);
    doc.line(8, 100, 203, 100);
    doc.line(8, 118, 203, 118);
    doc.line(8, 136, 203, 136);
    doc.line(8, 158, 203, 158);
    doc.line(8, 165, 203, 165);
    doc.line(8, 183, 203, 183);
    doc.line(8, 190, 203, 190);
    doc.line(8, 198, 203, 198);
    doc.line(8, 205, 203, 205);
    doc.line(8, 227, 203, 227);

    doc.line(8, 67.5, 8, 227);
    doc.line(203, 67.5, 203, 227);

    doc.line(103, 165, 103, 183);
    doc.line(83, 205, 83, 227);
    doc.line(156, 205, 156, 227);

    //Bloc 3 texte

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONTROLE FRAICHEUR DES OEUFS DE POISSON ", 105.5, 73, "center");

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("CONFORME", 16, 83);
    drawCheckbox(doc, 11, 83);

    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 9, 78, 7, 7);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("ACCEPTABLE", 55, 83);
    drawCheckbox(doc, 51, 83);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("NON CONFORME", 94, 83);
    drawCheckbox(doc, 90, 83);

    doc.setFontSize(12);
    doc.setFontStyle("normal");
    doc.text("Préciser la non-conformité :", 10, 91);
    doc.setLineDash([0.6]);
    doc.line(63, 91, 98, 91);
    doc.setLineDash();

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONTROLE DE FERMETURE VERRINES/BOITES ", 105.5, 98, "center");

    doc.setFontSize(10);
    doc.text("Sertissage machine/main : ", 10, 106);
    doc.setFontStyle("normal");
    doc.text(
        " Tire up (contrôle visuel sur toute les boites, le couvercle recouvre la boite): ",
        62,
        106
    );
    doc.text("Conforme", 10, 113);
    drawCheckbox(doc, 29, 113);
    doc.text("Non conforme", 61, 113);
    drawCheckbox(doc, 90, 113);

    doc.setFontStyle("bold");
    doc.text("Boite sous vide : ", 10, 124);
    doc.setFontStyle("normal");
    doc.text("(le couvercle doit tenir sur toute les boites): ", 43, 124);
    doc.text("Conforme", 10, 131);
    drawCheckbox(doc, 29, 131);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 27, 126, 7, 7);
    doc.text("Non conforme", 61, 131);
    drawCheckbox(doc, 90, 131);

    doc.setFontStyle("bold");
    doc.text("Eggxiting : ", 10, 142);
    doc.setFontStyle("normal");
    doc.text(
        " (Tirer sur la languette qui doit resister ,au démarrage): ",
        32,
        142
    );
    doc.text("Matin : Conforme", 10, 149);
    drawCheckbox(doc, 41, 149);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 39, 144, 7, 7);
    doc.text("Non conforme", 61, 149);
    drawCheckbox(doc, 97, 149);
    doc.text("Après midi : Conforme", 10, 155);
    drawCheckbox(doc, 50, 155);
    doc.text("Non conforme", 71, 155);
    drawCheckbox(doc, 108, 155);

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("BRIS DE VERRINE : PREVENIR SERVICE QUALITE", 105.5, 163, "center");
    doc.setFontSize(10);
    doc.text("Lieu : ", 9, 170);
    doc.text("Action : ", 106, 170);
    doc.setFontStyle("normal");
    doc.text("Salle préparation caviar ", 25.5, 170);
    drawCheckbox(doc, 74, 170);
    doc.text("Zone déballage carton ", 25.5, 178);
    drawCheckbox(doc, 74, 178);
    doc.text("Tri et lavage ", 124.5, 170);
    drawCheckbox(doc, 148, 170);
    doc.text("Destruction produit ", 158.5, 178);
    drawCheckbox(doc, 148, 178);
    doc.text("Destruction carton ", 113.5, 178);
    drawCheckbox(doc, 193, 178);

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text(
        "CONTROLE VERRE,METAL,PLASTIQUE DUR risquant de tomber dans le produit ",
        105.5,
        188,
        "center"
    );

    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text("Pas de casse ", 38, 195);
    drawCheckbox(doc, 33.5, 195);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 31.5, 190, 7, 7);
    doc.text("Casse : ", 117.5, 195);
    drawCheckbox(doc, 112, 195);
    doc.setLineDash([0.6]);
    doc.line(131.5, 195, 167, 195);
    doc.setLineDash(); //reset line param

    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text("CONFORMITE PRODUIT FINIS:", 105.5, 203, "center");
    doc.setFontSize(10);
    doc.setFontStyle("bold");
    doc.text("CONFORME", 13, 212);
    drawCheckbox(doc, 9, 212);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 7, 207, 7, 7);
    doc.text("NON CONFORME", 13, 217);
    drawCheckbox(doc, 9, 217);
    doc.text("OBSERVATIONS:", 84, 212);
    doc.text("Visa du chef d'équipe:", 157.5, 212);
    var signature = getDataUri("/sign", function(dataUri) {
        return dataUri;
    });
    doc.addImage(signature, 160, 207, 30, 30);
    //Bloc 4 lignes
    doc.line(8, 242, 203, 242);
    doc.line(8, 256, 203, 256);
    doc.line(8, 269, 203, 269);
    doc.line(8, 242, 8, 269);
    doc.line(203, 242, 203, 269);

    //Bloc 4 texte
    doc.setFontSize(10);
    doc.setFontStyle("normal");
    doc.text(
        "Contrôle nettoyage : (petit matériel, surface salles et plan de travail, plaque...) : ",
        10,
        236
    );
    doc.text("Propre", 147, 236);
    drawCheckbox(doc, 142, 236);
    data = getDataUri("/cross?cross=" + getRandomInt(8), function(dataUri) {
        return dataUri;
    });
    doc.addImage(data, 140, 231, 7, 7);
    doc.text("Sale", 167, 236);
    drawCheckbox(doc, 162, 236);
    doc.text("Actions correctives", 18.5, 246);
    drawCheckbox(doc, 13.5, 246);
    doc.text("Date et validation:", 8.5, 260);
    //sauvegarde doc

    doc.save(`Report-Caviar-${date}.pdf`);
    fetch("/database/done?date=" + date)
        .then((res) => console.log("DONE DONE"))
        .catch((err) => err);
    return doc;
};
